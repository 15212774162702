import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { Endpoints } from '../../endpoints';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import * as FileSaver from "file-saver";
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ScreenNames } from 'src/app/common/screenNames';
import { MessageObject } from 'src/app/common/models/UserModel';
import { SharedDataService } from 'src/app/common/util/sharedData.service';
import { ExporttoexcelService } from 'src/app/common/util/exportToExcel.service';
import { AuthorizationUtilService } from 'src/app/common/util/authorization.util.service';
import {
  LayoffCodesModel,
  DaysofweekModel,
  MasterProfileModel,
  IndividualHistoryModel,
  changeHistoryMasterModel,
  userDeleteMasterModel,
  DismissalReviewQueueMasterModel,
  ServiceUnitRangeMasterModel,
  GlobalPropertiesSearchMasterModel,
  activeProfileMasterModel,
  ApplicationAttributeVO,
  changeHistoryDetailsMasterModel,
  updateSecurityRoleMasterModel,
  saveSecurityUserMasterModel,
  EmployeeServiceUnitMasterModel,
  EmployeePointRangeMasterModel,
  SearchChangeHistoryMasterModel,
  SearchchangeHistoryModel,
  RegionOrServiceUnitDetailsVO,
  RegionOrServiceUnitDetails,
  SearchMasterProfileModel,
  IndividualSnapshotSearchModel,
  RecalculatePointsRequestModel,
  MessageModel,
  SearchMasterResponseModel,
  SearchIndividualHistoryProfileModel,
  SaveMasterProfileResponseModel,
  DismissalreviewqueueModel,
  SearchUsersSecurity,
  SearchEmployeeSecurityModel,
  pointRangeVo,
  EmployeeOnPointRangeVO,
  EmployeeSecurity,
  SecurityRoleSearchMasterModel,
  SecurityEditRoleMasterModel,
  SecurityRoleVO,
  SearchUsersSecurityResponse,
  EditEmployeeSecurityModel,
  saveSecurityRolesMasterModel,
  saveGlobalPropertiesMasterModel,
  updateGlobalPropertiesMasterModel,
  UserCapabilitiesModel,
  UserCapabilitiesMasterModel,
  DefaultRoleVO,
  roleDeleteMasterModel,
  CalendarViewResponseModel,
  BoardTypeModel,
  SearchBoardType,
  SearchBoardTypeModel,
  BoardType,
  EditBoardTypeCodesModel,
  saveBoardTypeCodesModel,
  updateBoardTypeCodesModel,
  BoardTypeCodeVO,
  StepProgressionSetupVO,
  SendToAPDSResponseModel,
  JobsStatusResponseModel,
  MissedcallReviewSearchResponseModel,
  EmployeeSearchResultsVO,
  EmployeeSearchResultsVOModel,
  EmployeeDetailsVOModel,
  EmployeeDetailsVO,
  EmployeePermBoardHistoryVOModel,
  EmployeeHistoryVOModel,
  SendTOFACTSVO,
  StatusReasonCodesModel,
  DisputereviewqueueModel,
  DisputequeueModel,
  DisputeQueueSearchModel,
  RaiseDispute,
  LateToReportModel,
  MedicalQueueModel, MedicalQueueSearchRequestVO, MedicalQueueVO
} from './data-api.model';
import { HttpResponse } from '@angular/common/http';
@Injectable()
export class DataAPIService {

  constructor(private httpClient: HttpClient, private authUtil: AuthorizationUtilService) {
    // if (sessionStorage.getItem('access-token')) {
    //   this.accessToken = sessionStorage.getItem('access-token');
    // }
    // if (sessionStorage.getItem('user')) {
    //   this.userDetails = <UserModel>JSON.parse(sessionStorage.getItem('user'));
    // }
  }

 
  public getAvailableBoardTypeCodes = (): Observable<BoardTypeCodeVO[]> => {
    let endpoint = Endpoints.getAllAvailableBoardTypeCodess;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let boardtypes: BoardTypeCodeVO[] = response.data;
      return boardtypes;
    }))
  }

 


  public securityeditRole = (oid: number): Observable<SecurityEditRoleMasterModel> => {
    let endpoint = `${Endpoints.securityeditRole}?roleOid=${oid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let securityeditRoleresults: SecurityEditRoleMasterModel = new SecurityEditRoleMasterModel();
      securityeditRoleresults.data = response.data;
      securityeditRoleresults.result = response.result;
      securityeditRoleresults.messages = response.messages;
      return securityeditRoleresults;
    }));  
  }
 
  public regionTypeAheads = (regionCode: string): Observable<any> => {
    let endpoint = `${Endpoints.regionTypeAheads}?regionCode=${regionCode}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let regiontypeaheadresponse: any = response.data;
      return regiontypeaheadresponse;
    }))
  }

  public getserviceCodes = (regionCode: string): Observable<any> => {
    let endpoint = `${Endpoints.getserviceCodes}?regionCode=${regionCode}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let serviceunittypeaheadresponse: any = response.data;
      return serviceunittypeaheadresponse;
    }))
  }

  public searchindividualSnapshot = (employeeOid: number): Observable<IndividualSnapshotSearchModel> => {
    let endpoint = `${Endpoints.searchindividualSnapshot}?employeeOid=${employeeOid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let searchindividualSnapshotresponse: IndividualSnapshotSearchModel = new IndividualSnapshotSearchModel();
      searchindividualSnapshotresponse.data = response.data;
      searchindividualSnapshotresponse.result = response.result;
      searchindividualSnapshotresponse.messages = response.messages;
      return searchindividualSnapshotresponse;
    }))
  }
  public getCalendarviewData = (employeeOid: number): Observable<CalendarViewResponseModel> => {
    let endpoint = `${Endpoints.getCalenderView}?employeeOid=${employeeOid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let calendarViewResponseModel: CalendarViewResponseModel = new CalendarViewResponseModel();
      calendarViewResponseModel.data = response.data;
      calendarViewResponseModel.result = response.result;
      calendarViewResponseModel.messages = response.messages;
      return calendarViewResponseModel;
    }))
  }


  public individualSnapshotPdf = (employeeOid: number): Observable<Blob> => {
    let endpoint = `${Endpoints.individualSnapshotPdf}?employeeOid=${employeeOid}`;

    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
       //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(endpoint, httpOptions);
  }

 


  




  public serviceUnitRegionPdf = (regions: string[], serviceUnit: number,pointRangeList : any,boardTypeOIds :string[] ): Observable<Blob> => {
    let endpoint = `${Endpoints.regionorServiceUnitPdf}?regions=${regions}&serviceUnit=${serviceUnit == null || undefined ? '' : serviceUnit}&pointRangeList=${pointRangeList}&boardTypeOIds=${boardTypeOIds}`;

    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
       //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(endpoint, httpOptions);
  }
  

 
 

  



  
  
 
 

  

 
 


  public securityUserEditPageLoadData = (row: EmployeeSecurity): Observable<EditEmployeeSecurityModel> => {
    let endpoint = `${Endpoints.securityUserEditPageLoadData}?employeeOid=${row.employeeOid == null || undefined ? '' : row.employeeOid}&craftCode=${row.craftCode == null || undefined ? '' : row.craftCode}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let searchresults: EditEmployeeSecurityModel = new EditEmployeeSecurityModel();
      searchresults.data = response.data;
      searchresults.result = response.result;
      searchresults.messages = response.messages;
      return searchresults
    }))
  }
  public submitDispute = (formData:any): Observable<any> => {
    let endpoint = `${Endpoints.raiseDispute}`;
    return this.httpClient.post<any>(endpoint,formData,{headers: new HttpHeaders({
                    'Content-Type': 'multipart/form-data'
                })}).pipe(map(response => {
      return response.data;
    }))
    
  }
  public deleteUserProfile = (oid: number): Observable<any> => {
    let endpoint = `${Endpoints.deleteUserProfile}?employeeOid=${oid}`;
    return this.httpClient.post<any>(endpoint, {});
  }
  public saveUserProfile = (payload: EmployeeSecurity): Observable<saveSecurityUserMasterModel> => {
    return this.httpClient.post<any>(Endpoints.saveUserProfile, payload).pipe(map(response => {
      let savedProfile: saveSecurityUserMasterModel = new saveSecurityUserMasterModel();
      savedProfile.data = response.data;
      savedProfile.messages = response.messages;
      savedProfile.result = response.result;
      return savedProfile;
    }))
  }
  public getserviceunitregion = (region: string[], serviceUnit: number): Observable<RegionOrServiceUnitDetails> => {
    let endpoint = `${Endpoints.getregionorServiceUnit}?region=${region}&serviceUnit=${serviceUnit == null || undefined ? '' : serviceUnit}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let serviceunitregionsearchResults: RegionOrServiceUnitDetails = new RegionOrServiceUnitDetails;
      serviceunitregionsearchResults.data = response.data;
      serviceunitregionsearchResults.messages = response.messages;
      serviceunitregionsearchResults.result = response.result;
      return serviceunitregionsearchResults;
    }))
  }
  public updateUserProfile = (payload: EmployeeSecurity): Observable<saveSecurityUserMasterModel> => {
    return this.httpClient.post<any>(Endpoints.updateUserProfile, payload).pipe(map(response => {
      let savedProfile: saveSecurityUserMasterModel = new saveSecurityUserMasterModel();
      savedProfile.data = response.data;
      savedProfile.messages = response.messages;
      savedProfile.result = response.result;
      return savedProfile;
    }))
  }

  public dismissalReviewDelete = (employeeId: string, histOid: number): Observable<any> => {
    let endpoint = `${Endpoints.dismissalreviewqueueDelete}?employeeId=${employeeId}&histOid=${histOid}`;
    return this.httpClient.post<any>(endpoint, {});
  }
  public sendToDRQ = (selectedEmployeeOIds: any): Observable<SendToAPDSResponseModel> => {
    return this.httpClient.post<any>(Endpoints.sendToAPDS, selectedEmployeeOIds).pipe(map(response => {
      let sendToAPDSResponseModel: SendToAPDSResponseModel = new SendToAPDSResponseModel();
      sendToAPDSResponseModel.data = response.data;
      sendToAPDSResponseModel.messages = response.messages;
      sendToAPDSResponseModel.result = response.result;
      return sendToAPDSResponseModel;
    }))
  }
  public disputereviewqueuePdf = (disputeReq: any): Observable<Blob> => {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
    };
    return this.httpClient.post<Blob>(Endpoints.disputereviewqueuePdf, disputeReq, httpOptions);
  }
  public disputereviewqueueSearch = (employeeId: number, region: string, serviceUnit: number, supvId:number): Observable<DisputereviewqueueModel> => {
    let endpoint = `${Endpoints.disputereviewqueueSearch}?employeeId=${employeeId == null || undefined ? '' : employeeId}&region=${region}&serviceUnit=${serviceUnit == null || undefined ? '' : serviceUnit}&supvId=${supvId==null || undefined?'': supvId}`; return this.httpClient.get<any>(endpoint).pipe(map(response => {
    let disputereviewqueuesearchResults: DisputereviewqueueModel = new DisputereviewqueueModel;
    disputereviewqueuesearchResults.data = response.data;
    disputereviewqueuesearchResults.messages = response.messages;
    disputereviewqueuesearchResults.result = response.result;
    return disputereviewqueuesearchResults;
    }))
    }
    public disputequeueSearch = (disputeReq: any): Observable<DisputequeueModel> => {
      let endpoint = `${Endpoints.disputequeueSearch}`; 
      return this.httpClient.post<any>(endpoint, disputeReq).pipe(map(response => {
      let disputequeuesearchResults: DisputequeueModel = new DisputequeueModel;
      disputequeuesearchResults.data = response.data;
      disputequeuesearchResults.messages = response.messages;
      disputequeuesearchResults.result = response.result;
      return disputequeuesearchResults;
      }))
    }

    public sendToAPDS = (selectedApdsRecords: any): Observable<Blob> => {
      const httpOptions = {
        'responseType'  : 'arraybuffer' as 'json'
      };
      return this.httpClient.post<Blob>(Endpoints.sendToAPDSUrl, selectedApdsRecords,httpOptions);
    }
    public updateDisputeQueue = (payload: any): Observable<updateGlobalPropertiesMasterModel> => {
      return this.httpClient.post<any>(Endpoints.globalPropertiesSave, payload).pipe(map(response => {
        let getglobalPropertiesEditresults = new updateGlobalPropertiesMasterModel();
        getglobalPropertiesEditresults.data = response.data;
        getglobalPropertiesEditresults.result = response.result;
        getglobalPropertiesEditresults.messages = response.messages;
        return getglobalPropertiesEditresults;
      }));
    }
    public securityDistinctServiceUnitTypeAheads = (): Observable<any> => {
      let endpoint = Endpoints.securityDistinctServiceUnitTypeAheads;
      return this.httpClient.get<any>(endpoint).pipe(map(response => {
        let serviceunittypeaheadresponse: any = response.data;
        return serviceunittypeaheadresponse;
      }))
    }
    public getReasons = () => {
      let endpoint = Endpoints.getglobalProperties;
      return this.httpClient.post<any>(endpoint,{"type":"include/exclude reason"}).pipe(map(response => {
        let getglobalPropertiesResults = response.data;
        return getglobalPropertiesResults;
      }))
    }
    public employeeTypeAheads = (startingWith: string): Observable<any> => {
      let endpoint = `${Endpoints.employeeTypeAheads}?startingWith=${startingWith}`;
      return this.httpClient.get<any>(endpoint).pipe(map(response => {
        let typeaheadresponse: any = response.data;
        return typeaheadresponse;
      }))
    }
    public securityRegionTypeAheads = (): Observable<any> => {
      let endpoint = Endpoints.securityRegionTypeAheads;
      return this.httpClient.get<any>(endpoint).pipe(map(response => {
        let regiontypeaheadresponse: any = response.data;
        return regiontypeaheadresponse;
      }))
    }
    public saveDispute = (disputeReq:any): Observable<any> => {
      let endpoint = `${Endpoints.saveDispute}`;
      return this.httpClient.post<any>(endpoint, disputeReq);
    }
 

 
  public editUserSecurity = (oid: number): Observable<any> => {
    let endpoint = `${Endpoints.editUserSecurity}?employeeOid=${oid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let editUserSecurityResults = response.data;
      return editUserSecurityResults

    }))
  }
  public serviceUnitSearch = (pointRangeVo: string[], regions: string[], serviceUnit: number, supvId: number) => {
    let endpoint = `${Endpoints.serviceUnitSearch}?pointRangeList=${pointRangeVo}&regions=${regions}&serviceUnit=${serviceUnit == null || undefined ? '' : serviceUnit}&supvId=${supvId == null || undefined ? '' : supvId}`;
    endpoint = endpoint.replace("+", "%2B");
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let serviceunitregionsearchResults: RegionOrServiceUnitDetails = new RegionOrServiceUnitDetails;
      serviceunitregionsearchResults.data = response.data;
      serviceunitregionsearchResults.messages = response.messages;
      serviceunitregionsearchResults.result = response.result;
      return serviceunitregionsearchResults;
    }));
  }
  public serviceUnitsforRegions = (regionOids: any) => {
    let endpoint = `${Endpoints.serviceUnitsforRegions}?regionOids=${regionOids}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let serviceUnitsforRegionsResults = response.data;
      return serviceUnitsforRegionsResults;
    }));
  }
  public getSuperVisorTypeAheads = (searchSuperVisor): Observable<any> => {
    let endpoint = `${Endpoints.superVisorTypeAheads}?supervisorName=${searchSuperVisor}`
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let superVisorTypeAheads: any = response.data;
      return superVisorTypeAheads;
    }))
  }

  public saveSecurityRole = (payload: SecurityRoleVO): Observable<saveSecurityRolesMasterModel> => {
    return this.httpClient.post<any>(Endpoints.saveSecurityRole, payload).pipe(map(response => {
      let savedRole: saveSecurityRolesMasterModel = new saveSecurityRolesMasterModel();
      savedRole.messages = response.messages;
      savedRole.result = response.result;
      return savedRole;
    }))
  }
  public updateSecurityRole = (payload: SecurityRoleVO): Observable<updateSecurityRoleMasterModel> => {
    return this.httpClient.post<any>(Endpoints.updateSecurityRole, payload).pipe(map(response => {
      let savedRole: updateSecurityRoleMasterModel = new updateSecurityRoleMasterModel();
      savedRole.data = response.data;
      savedRole.messages = response.messages;
      savedRole.result = response.result;
      return savedRole;
    }))
  }

 

  public globalPropertiesSave = (payload: ApplicationAttributeVO): Observable<saveGlobalPropertiesMasterModel> => {
    return this.httpClient.post<any>(Endpoints.globalPropertiesSave, payload).pipe(map(response => {
      let activeProfileResults: saveGlobalPropertiesMasterModel = new saveGlobalPropertiesMasterModel();
      activeProfileResults.data = response.data;
      activeProfileResults.messages = response.messages;
      activeProfileResults.result = response.result;
      return activeProfileResults;
    }));
  }
  public getglobalPropertiesEdit = (oid: number): Observable<updateGlobalPropertiesMasterModel> => {
    let endpoint = `${Endpoints.getglobalPropertiesEdit}?oid=${oid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let getglobalPropertiesEditresults: updateGlobalPropertiesMasterModel = new updateGlobalPropertiesMasterModel();
      getglobalPropertiesEditresults.data = response.data;
      getglobalPropertiesEditresults.result = response.result;
      getglobalPropertiesEditresults.messages = response.messages;
      return getglobalPropertiesEditresults;
    }))
  }
  public globalPropertiesUpdate = (payload: ApplicationAttributeVO): Observable<updateGlobalPropertiesMasterModel> => {
    return this.httpClient.post<any>(Endpoints.globalPropertiesSave, payload).pipe(map(response => {
      let getglobalPropertiesEditresults = new updateGlobalPropertiesMasterModel();
      getglobalPropertiesEditresults.data = response.data;
      getglobalPropertiesEditresults.result = response.result;
      getglobalPropertiesEditresults.messages = response.messages;
      return getglobalPropertiesEditresults;
    }));
  }



 

  public activeProfile = (): Observable<activeProfileMasterModel> => {
    let endpoint = Endpoints.activeProfile;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let activeProfileResults: activeProfileMasterModel = new activeProfileMasterModel();
      activeProfileResults.data = response.data;
      activeProfileResults.messages = response.messages;
      activeProfileResults.result = response.result;
      return activeProfileResults;
    }))
  }
  public userCapabilities = (): Observable<UserCapabilitiesMasterModel> => {
    let endpoint = Endpoints.userCapabilities;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let userCapabilitiesResults = new UserCapabilitiesMasterModel;
      userCapabilitiesResults.messages = response.messages;
      userCapabilitiesResults.result = response.result;
      userCapabilitiesResults.data = response.data;;
      return userCapabilitiesResults;
    }));

  }
  public roleSwitchCapabilities = (oid: string): Observable<DefaultRoleVO> => {
    let endpoint = `${Endpoints.roleSwitchCapabilities}/${oid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let roleSwitchCapabilitiesResults = response.data;
      return roleSwitchCapabilitiesResults;
    }))
  }
  public authenticateUser = (body:any): Observable<string> => {
    let endpoint = `${Endpoints.authenticateUser}`;
    return this.httpClient.post<any>(endpoint,body).pipe(map(response => {
      let authenticateUserResults = response.data;
      return authenticateUserResults;
    }))
  }

   public authenticateOkta = (body:any): Observable<any> => {
	    let endpoint = `${Endpoints.authenticateOkta}`;
	    return this.httpClient.post<any>(endpoint,body).pipe(map(response => {
	      return response;
	    }))
	  }
 
	public authenticateOktaUser = (employeeId: string): Observable<any> => {
	    let endpoint = `${Endpoints.authenticateOktaUser}/${employeeId}`;
		return this.httpClient.post<any>(endpoint,{}).pipe(map(response => {
	      let authenticateUserResults = response.data;
	      return authenticateUserResults;
	    }))
  	}

	public authenticateLogout = (body:any): Observable<any> => {
	    let endpoint = `${Endpoints.authenticateOktaLogout}`;
	    return this.httpClient.post<any>(endpoint,body).pipe(map(response => {
	      return response;
	    }))
	} 

  public upAuthenticate = () => {
    let endpoint = Endpoints.upAuthenticate;
    return this.httpClient.get<any>(endpoint);
  }
  public userCopy = (employeeOid: number,craftCode : string): Observable<any> => {
    let endpoint;
    if(employeeOid){
       endpoint = `${Endpoints.userCopy}?employeeOid=${employeeOid}`;
    }else{
       endpoint = `${Endpoints.userCopy}?craftCode=${craftCode}`;
    }
    return this.httpClient.get<any>(endpoint);
  }
  public roleCopy = (roleOid: number): Observable<any> => {
    let endpoint = `${Endpoints.roleCopy}?roleOid=${roleOid}`;
    return this.httpClient.get<any>(endpoint);
  }
 
 
 
 
y
  public searchBoardTypeCodes = (row: SearchBoardType): Observable<SearchBoardTypeModel> => {

    let endpoint = `${Endpoints.boardTypeCodesSearch}?boardType=${row.boardType == null || undefined ? '' : row.boardType}&boardCode=${row.boardCode == null || undefined ? '' : row.boardCode}`;
      return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let searchresults: SearchBoardTypeModel = new SearchBoardTypeModel();
      searchresults.data = response.data;
      searchresults.result = response.result;
      searchresults.messages = response.messages;
      return searchresults
    }))
  }


  public getAllBoardTypes = (): Observable<any> => {
    let endpoint = Endpoints.getAllBoardTypes;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let boardTypeResponse: any = response.data;
      return boardTypeResponse;
    }))
  }
 
 
  public boardTypeEditPageLoadData = (boardTypeOid: number,type: string): Observable<EditBoardTypeCodesModel> => {
    let endpoint = `${Endpoints.boardTypeEditPageLoadData}?boardTypeOid=${boardTypeOid}&boardType=${type}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let searchresults: EditBoardTypeCodesModel = new EditBoardTypeCodesModel();
      searchresults.data = response.data;
      searchresults.result = response.result;
      searchresults.messages = response.messages;
      return searchresults
    }))
  }


  public saveBoardTypeCodes = (payload: BoardType): Observable<saveBoardTypeCodesModel> => {
    return this.httpClient.post<any>(Endpoints.addBoardTypeCodes, payload).pipe(map(response => {
      let savedBoardTypeCodes: saveBoardTypeCodesModel = new saveBoardTypeCodesModel();
      savedBoardTypeCodes.data = response.data;
      savedBoardTypeCodes.messages = response.messages;
      savedBoardTypeCodes.result = response.result;
      return savedBoardTypeCodes;
    }))
  }
  public updateBoardTypeCodes = (payload: BoardType): Observable<updateBoardTypeCodesModel> => {
    return this.httpClient.post<any>(Endpoints.updateBoardTypeCodes, payload).pipe(map(response => {
      let updateBoardTypeCodes: updateBoardTypeCodesModel = new updateBoardTypeCodesModel();
      updateBoardTypeCodes.data = response.data;
      updateBoardTypeCodes.messages = response.messages;
      updateBoardTypeCodes.result = response.result;
      return updateBoardTypeCodes;
    }))
  }


  public boardTypeDelete = (boardTypeOid: number): Observable<any> => {
    let endpoint = `${Endpoints.deleteBoardTypeCode}?boardTypeOid=${boardTypeOid}`;
    return this.httpClient.post<any>(endpoint, {});
  }

  public logout = (): Observable<any> => {
    let endpoint = `${Endpoints.logout}`;
    return this.httpClient.post<any>(endpoint, {});
  }

 

  public getAlljobsStatus =() : Observable<JobsStatusResponseModel> =>{
    return this.httpClient.get<any>(Endpoints.getAllJobsStatus).pipe(map(response =>{
      let jobsStatusModel : JobsStatusResponseModel = new JobsStatusResponseModel();
      jobsStatusModel.data = response.data;
      jobsStatusModel.messages = response.messages;
      jobsStatusModel.result = response.result;
      return jobsStatusModel;
    }))
  }

  public triggerBatchJob =(endpoint: string) : Observable<any> =>{
    return this.httpClient.get<any>(endpoint).pipe(map(response =>{
      return '';
    }))
  }

  public triggerCreditBatchJob =(endpoint: string,isTey:boolean ,issueCredit :boolean) : Observable<any> =>{
    endpoint = `${endpoint}?isTEYEmployee=${isTey}&issueCredits=${issueCredit}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response =>{
      return '';
    }))
  }

  public getMissedcallEvents = (employeeOId : number,region : string,serviceUnit : string,startDate : string,endDate :string,statusCode: string) : Observable<MissedcallReviewSearchResponseModel> =>{
    let endpoint = `${Endpoints.getMissedcallLayoffEvents}?employeeOId=${employeeOId?employeeOId:''}&region=${region?region:''}&serviceUnit=${serviceUnit?serviceUnit:''}&startDate=${startDate?startDate:''}&endDate=${endDate?endDate:''}&statusCode=${statusCode?statusCode:''}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response =>{
      let missedcallReviewSearchResponseModel: MissedcallReviewSearchResponseModel = new MissedcallReviewSearchResponseModel();
      missedcallReviewSearchResponseModel.data = response.data;
      missedcallReviewSearchResponseModel.messages = response.messages;
      missedcallReviewSearchResponseModel.result = response.result;
      return missedcallReviewSearchResponseModel;
    }))
  }
  public validateMissedcallLayoff = (payload: RecalculatePointsRequestModel): Observable<any> => {
    return this.httpClient.post<any>(Endpoints.validateMissedcallLayoff, payload).pipe(map(response => {
     
    }));
  }

  public missedcallReviewPdf = (employeeOId : number,region : string,serviceUnit : string,startDate : string,endDate :string,statusCode:string ): Observable<Blob> => {
    let endpoint = `${Endpoints.missedcallReviewPdfGeneration}?employeeOId=${employeeOId?employeeOId:''}&region=${region?region:''}&serviceUnit=${serviceUnit?serviceUnit:''}&startDate=${startDate?startDate:''}&endDate=${endDate?endDate:''}&statusCode=${statusCode?statusCode:''}`;
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
       //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(endpoint, httpOptions);
  }

  public employeeMasterSearch = (employeeOid: number, region: string, serviceUnit: number ,isActiveFlag : string ,onHoldFlag : string ): Observable<EmployeeSearchResultsVOModel> => {
    let endpoint = `${Endpoints.employeeMasterSearch}?employeeOid=${employeeOid == null || undefined ? '' : employeeOid}&region=${region}&serviceUnit=${serviceUnit == null || undefined ? '' : serviceUnit}&isActiveFlag=${isActiveFlag}&onHoldFlag=${onHoldFlag}`;

    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let employeeSearchResults: EmployeeSearchResultsVOModel = new EmployeeSearchResultsVOModel   ;
      employeeSearchResults.data = response.data;
      employeeSearchResults.messages = response.messages;
      employeeSearchResults.result = response.result;
      return employeeSearchResults;
    }))
  }
  public getEmployeeDetails = (employeeOid: number): Observable<EmployeeDetailsVOModel> => {
    let endpoint = `${Endpoints.getEmployeeDetails}?employeeOid=${employeeOid == null || undefined ? '' : employeeOid}`;

    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let employeeDetails: EmployeeDetailsVOModel = new EmployeeDetailsVOModel   ;
      employeeDetails.data = response.data;
      employeeDetails.messages = response.messages;
      employeeDetails.result = response.result;
      return employeeDetails;
    }))
  }
  public updateEmployeeMaster = (employeeDetails: EmployeeDetailsVO): Observable<EmployeeDetailsVOModel> => {
    return this.httpClient.post<any>(Endpoints.updateEmployeeMaster, employeeDetails).pipe(map(response => {
      let employeeDetails: EmployeeDetailsVOModel = new EmployeeDetailsVOModel   ;
      employeeDetails.data = response.data;
      employeeDetails.messages = response.messages;
      employeeDetails.result = response.result;
      return employeeDetails;
    }))
  }

  public getEmployeeHistory = (employeeOid: number): Observable<EmployeeHistoryVOModel> => {
    let endpoint = `${Endpoints.getEmployeeHistory}?employeeOid=${employeeOid == null || undefined ? '' : employeeOid}`;

    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let employeeDetails: EmployeeHistoryVOModel = new EmployeeHistoryVOModel   ;
      employeeDetails.data = response.data;
      employeeDetails.messages = response.messages;
      employeeDetails.result = response.result;
      return employeeDetails;
    }))
  }
  public uploadSQLFile = (file: File): Observable<any> => {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.httpClient.post<any>(Endpoints.uploadSQLFile, formData).pipe(map(response => {
     
    }))

  }
  public executeSQL = (query: string): Observable<any> => {


    return this.httpClient.post<any>(Endpoints.exceuteSQL, query).pipe(map(response => {
     
    }))

  }

  public getMasterProfile = (oid: number): Observable<MasterProfileModel> => {
    let endpoint = `${Endpoints.editmasterProfile}?profileOid=${oid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let editprofileresponse = response.data;
      return editprofileresponse;
    }))
  }

  public copymasterProfile = (oid: number): Observable<MasterProfileModel> => {
    let endpoint = `${Endpoints.copymasterProfile}?profileOid=${oid}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let copyprofileresponse: MasterProfileModel = response.data;
      return copyprofileresponse;
    }))
  }

  public searchmasterProfile = (row: SearchMasterProfileModel): Observable<SearchMasterResponseModel> => {
    let endpoint = `${Endpoints.searchmasterProfile}?profileId=${row.profileId}&effectiveDate=${row.effectiveDate}&expiryDate=${row.expiryDate}&includeInactive=${row.status}`;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let searchresults: SearchMasterResponseModel = new SearchMasterResponseModel();
      searchresults.data = response.data;
      searchresults.result = response.result;
      searchresults.messages = response.messages;
      return searchresults
    }))
  }

  public getlayoffCodes = (): Observable<LayoffCodesModel[]> => {
    let endpoint = Endpoints.getlayoffCodes;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let layoffCodes: LayoffCodesModel[] = response.data;
      return layoffCodes;
    }))
  }

  public getAlllayoffCodes = (): Observable<LayoffCodesModel[]> => {
    let endpoint = Endpoints.getAlllayoffCodes;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let layoffCodes: LayoffCodesModel[] = response.data;
      return layoffCodes;
    }))
  }

  public getActionTemplates = (type :string): Observable<ApplicationAttributeVO[]> => {
    let endpoint = Endpoints.getAllEmailTemplates;
    return this.httpClient.post<any>(endpoint,{"type":type}).pipe(map(response => {
      let actionTemplates: ApplicationAttributeVO[] = response.data;
      return actionTemplates;
    }))
  }
  
  public intiatePointGuage = (): Observable<StepProgressionSetupVO> => {
    let endpoint = Endpoints.intiatePointGuage;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let StepProgressionSetupVO = response.data;
      return StepProgressionSetupVO;
    }))
  }

  public getAttendanceMonitorPdf = (): Observable<Blob> => {
    let endpoint = Endpoints.attendanceMonitorPdf;
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
       //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(endpoint, httpOptions);
  }

  public getglobalProperties = (type: string): Observable<any> => {
    let endpoint = Endpoints.getglobalProperties;
    return this.httpClient.post<any>(endpoint,{"type":type}).pipe(map(response => {
      let getglobalPropertiesResults = response.data;
      return getglobalPropertiesResults;
    }))
  }
  public getemployeeGauge = (body:any): Observable<DismissalReviewQueueMasterModel> => {
    let endpoint = `${Endpoints.getemployeeGauge}`;
    return this.httpClient.post<any>(endpoint,body).pipe(map(response => {
      let getEmployeeGaugeResults: DismissalReviewQueueMasterModel = new DismissalReviewQueueMasterModel();
      getEmployeeGaugeResults.data = response.data;
      getEmployeeGaugeResults.result = response.result;
      getEmployeeGaugeResults.messages = response.messages;
      return getEmployeeGaugeResults;
    }))
  }

  public globalPropertiesSearch = (name: string, type: string): Observable<GlobalPropertiesSearchMasterModel> => {
    let endpoint = Endpoints.globalPropertiesSearch;
    return this.httpClient.post<any>(endpoint,{"name":name,"type":type}).pipe(map(response => {
      let globalPropertiesSearchResults = new GlobalPropertiesSearchMasterModel();
      globalPropertiesSearchResults.data = response.data;
      globalPropertiesSearchResults.messages = response.messages;
      globalPropertiesSearchResults.result = response.result;
      return globalPropertiesSearchResults;
    }))
  }

  public getBoardTypes = (): Observable<BoardTypeModel[]> => {
    let endpoint = Endpoints.getAllLiteBoardTypes;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let boardtypes: BoardTypeModel[] = response.data;
      return boardtypes;
    }))
  }

  public getdaysofWeek = (): Observable<DaysofweekModel[]> => {
    let endpoint = Endpoints.getdaysofWeek;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let daysofWeeks: DaysofweekModel[] = response.data;
      return daysofWeeks
    }))
  }

  public getMasterProfilePdf = (oid: number): Observable<Blob> => {
    let endpoint = `${Endpoints.masterProfilePdf}?profileOid=${oid}`;
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
       //'responseType'  : 'blob' as 'json'        //This also worked
    };

    return this.httpClient.get<Blob>(endpoint, httpOptions);
  }

  public savemasterProfile = (payload): Observable<SaveMasterProfileResponseModel> => {
    return this.httpClient.post<any>(Endpoints.savemasterProfile, payload).pipe(map(response => {
      let saveResults: SaveMasterProfileResponseModel = new SaveMasterProfileResponseModel();
      saveResults.result = response.result;
      saveResults.messages = response.messages;
      return saveResults;
    }));
  }

  public updatemasterProfile = (payload): Observable<SaveMasterProfileResponseModel> => {
    return this.httpClient.post<any>(Endpoints.updatemasterProfile, payload).pipe();
  }


  public getStatusReasonCodes = (): Observable<StatusReasonCodesModel[]> => {
    let endpoint = Endpoints.getLayoffReasonCodes;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      let statusCodes: StatusReasonCodesModel[] = response.data;
      return statusCodes;
    }))
  }
  
  public uploadEventFile (formData:FormData){
    let endpoint = Endpoints.uploadEventLoad;
    return this.httpClient.post<any>(endpoint,formData,{headers: new HttpHeaders({
                    'Content-Type': 'multipart/form-data'
                })}).pipe(map(response => {
      return response.data;
    }))
  }
  
  public getDisputeFiles = (disputeEventAdjmentOid:number): Observable<any> => {
    let endpoint = Endpoints.getDisputeFiles+disputeEventAdjmentOid;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      return response.data;
    }))
  }
  
  public getDisputeFileDownload1 = (fileOid:number): Observable<any> => {
    let endpoint = Endpoints.getDisputeFileDownload+fileOid;
    return this.httpClient.get<any>(endpoint).pipe(map(response => {
      return response.data;
    }))
  }
  
  public getDisputeFileDownload(fileOid:number){

    let endpoint = Endpoints.getDisputeFileDownload+fileOid;

    return this.httpClient.get(endpoint, {responseType: 'blob'});
  }
  
  public saveLTREvent = (payload: LateToReportModel): Observable<SearchIndividualHistoryProfileModel> => {
    return this.httpClient.post<any>(Endpoints.saveLateToReportUrl, payload).pipe(map(response => {
      let recalculateResults: SearchIndividualHistoryProfileModel = new SearchIndividualHistoryProfileModel();
      recalculateResults.data = response.data;
      recalculateResults.result = response.result;
      recalculateResults.messages = response.messages;
      return recalculateResults;
    }));
  }
}
