export class LayoffCodesModel {
  statusCode: string;
  description: string;
  codeDescription: string;
  statusCodeOid: any;
}

export class PickListModel {
  pickListConfig: any;
  statusReasonCodePickListConfig: any;
  boardPickListConfig: any;
  craftGroupPickListConfig: any;
  availableList: LayoffCodesModel[] = [];
  availableBoardsList: BoardTypeModel[] = [];
  availableStatusReasonCodesList: BoardTypeModel[] = [];
  availableCraftGroupsList: CraftGroupsModel[] = [];
  selectedList: LayoffCodesModel[] = [];
  selectedBoardsList: BoardTypeModel[] = [];
  selectedStatusReasonCodesList: StatusReasonCodesModel[] = [];
  selectedCraftGroupsList: CraftGroupsModel[] = [];
  points: number;
  beforeOrAfterPoint: number;
  pointsAfter24Hours :  number;
  actionOid :number
}
export class FeatureRoleVO {
  createdUser: String;
  createdTimeStamp: String;
  lastUpdatedUser: String;
  lastUpdatedTimeStamp: String;
  applicationAttributeOid: number;
  securityScreenCapabilityOid: number;
  featureName: String;
  featureCapabilityDescprition: String;
  isEnabled: boolean;
}
export class StatusReasonCodesModel {
  statusReasonCodeOid: number;
  statusCodeOid: number;
  statusReasonCodeDesc: string;
  statusCode: string;
  reasonCode: string;
  description: string;
  createdUser: string;
  activeFlag: boolean;
  createdTimeStamp: string;
  disqualifyFlag: boolean;
  lastUpdatedUser: string;
  lastUpdatedTimeStamp: string;
}
export class QualifyingLayoffSetupModel {
  qualifyingLayoffSetup: PickListModel[] = [];
  pointsPerYearWithoutIncident: number;
  periodWithoutIncident: number;
  pointsPerPeriodWithoutIncident: number;
}
export class DaysofweekModel {
  day: string;
}
export class BoardTypeModel {
  boardTypeOid: number;
  boardName: string;
}
export class PickListDaysModel {
  pickListConfig: any;
  availableList: DaysofweekModel[] = [];
  selectedList: DaysofweekModel[] = [];
}
export class PickListBoardTypesModel {
  pickListConfig: any;
  availableList: BoardTypeModel[] = [];
  selectedList: BoardTypeModel[] = [];
}

export class QualifyingVO {
  day: string;
  startTime: string;
  endTime: string;
  selected: boolean;
}


export class QualifyingSetupVO {
  layoffCodesGroupVOs: LayoffCodesGroupVO[] = [];
  action: string;
  periodWithoutIncident: number;
  pointsPerPeriodWithoutIncident: number;
  pointsPerYearWithoutIncident: number;
}
export class MasterProfileModel {
  oid: number;
  layoffCodeSetupVOs: LayoffCodeSetupVO[] = [];
  profileId: string;
  description: string;
  effectiveDate: string;
  expiryDate: string;
  comments: string;
  dayofWeekSetupVO: DayofWeekSetupVO;
  peakWeekSetupVO: PeakWeekSetupVO;
  holidaySetupVO: HolidaySetupVO;
  qualifyingLayoffSetupVO: QualifyingSetupVO;
  pointReductionSetupVO: PointReductionSetupVO;
  specialConditionCodeSetupVOS : SpecialCondtionGroupVO[] = [];
   stepProgressionSetupVO: StepProgressionSetupVO = new StepProgressionSetupVO();
}
export class LayoffCodeSetupVO {
  layoffCodesGroupVO: LayoffCodesGroupVO;
  beforeAfterRestDayPoints: number;
}
export class LayoffCodesGroupVO {
  statusCodes: StatusReasonCodesModel[] = [];
  statusReasonCodes: StatusReasonCodesModel[] = [];
  boardTypes: BoardTypeModel[] = [];
  points: number;
  pointsAfter24Hours: number;
  groupId: number;
}

export class SpecialCondtionGroupVO {
  statusCodes: StatusReasonCodesModel[] = [];
  craftGroups: CraftGroupsModel[] = [];
  actionOid : number;
  groupId: number;
}
export class DayofWeekSetupVO {
  dayOfweekVOs: DayofweekModel[] = [];
  layoffCodesGroupVOs: LayoffCodesGroupVO[] = [];
}
export class DayofweekModel {
  day: string;
  startTime: string;
  endTime : string;
  selected:boolean;
}
enum DayOfweekVO {
  SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY
}
export class PeakWeekSetupVO {
  peakWeekVOs: PeakWeekVO[] = [];
  layoffCodesGroupVOs: LayoffCodesGroupVO[] = [];
}
export class PeakWeekVO {
  startDate: string;
  endDate: string;
}
export class HolidaySetupVO {
  holidayVOs: HolidayVO[] = [];
  layoffCodesGroupVOs: LayoffCodesGroupVO[] = [];
}
export class HolidayVO {
  date: string;
}
export class PointReductionSetupVO {
  layoffCodesGroupVOs: LayoffCodesGroupVO[] = [];
  action: string;
  noOfDaysWithoutIncident: number;
  pointsForDaysWithoutIncident: number;
}
export class StepProgressionSetupVO {
  action:string;
  dismissalStepsList: StepPoint[];
  pointsPerYearsOfService:number;
  yearsOfService:number;
}
export class CraftGroupsModel {
  type: string;
}
// export class StepProgressionGroupVO {
//   step: string;
//   pointsStepReached: number;
//   pointsReducedAfter: number;
//   email: string;
// }
export class MessageModel {
  code: string;
  description: string;
  //objectIdentifier: null
  systemMessage: string;
  type: string
}
export class StepPoint{
  stepNum: string;
  stepPoints: string;
  actionTemplateOid: number;
}
export class SearchMasterResponseModel {
  result: string;
  data: SearchMasterProfileModel[];
  messages: MessageModel[];
}
export class SaveMasterProfileResponseModel {
  result: string;
  data: MasterProfileModel[];
  messages: MessageModel[];
}
export class SearchMasterProfileModel {
  profileId: string = '';
  description: string = '';
  effectiveDate: string = '';
  expiryDate: string = '';
  status = false;
  oid: number;
}
export class CheckboxValues {
  true = 'Y';
  false = 'N';
  undefined = 'N';
  null = 'N';
}
export class StartEndDateModel {
  startDate: string;
  endDate: string;
}
export class MessageObject {
  type = '';
  systemMessage = '';
  systemMessages = [];
}
export class MessageObjects {
  type = '';
  systemMessages = [];
}
export class SearchIndividualHistoryProfileModel {
  result: string;
  data: IndividualHistoryModel[];
  messages: MessageModel
}
export class IndividualHistoryModel {
  employeeId: string;
  employeeOid: number;
  permanentBoard : string;
  employeeName: string;
  endDate: string;
  holidayLayOffs: any;
  jobsAndDHs: any;
  layoffEvents: layoffEvents[] = [];
  missedCallLayOffs: any;
  sickLayOffs: any;
  startDate: string;
  totalNonCompLayOffs: any;
  totalPoints: number;
  weekendLayOffs: any;
  credits: number;
  startdate :string;
  enddate :string;
  attendanceStatus :string;
  retentionEndDate: string;
  inactiveSinceDate :string;
}
export class layoffEvents {
  comments: string;
  reason : string;
  date: string;
  dayOfWeek: string;
  eventOid: number;
  excludeEvent: boolean;
  jobId: any;
  locationCode: string;
  points: number;
  actualPoints : string;
  dailyPoints : number;
  breakCredit : string;
  startTime: string;
  statusCode: string;
  locationId:string;
  vettingAdjustment :string;
  layHistOid : number;
  systemGenerated : boolean;
  startTimeStamp : string;
  priorLCCEvent: boolean;
}
export class DismissalreviewqueueModel {
  result: string;
  data: DismissalReviewQueueSearchModel[];
  messages: MessageModel
}
export class DismissalReviewQueueSearchModel {
  checked = false;
  employeeFirstName: string;
  employeeLastName: string;
  employeeOid: number;
  employeeId : string;
  locationCode: string;
  districtCode: string;
  craftCode: string;
  subDistrictCode: string;
  locationId: string;
  pointsAssesed: any;
  region: string;
  serviceUnit: string;
  startTime: string;
  statusCode: string;
  statusDescription: string;
  totalPoints: number;
  thresoldDate: string;
  attendanceStatus: string;  
  hireDate:string;
}
export class DismissalQueueSearchModel {
  checked = false;
  employeeFirstName: string;
  employeeLastName: string;
  employeeOid: number;
  employeeId : number;
  district: string;
  subdistrict: string;
  craft: string;
  statusCode: string;
  stepLevel: string; 
  pointAccessed: string;
  thresoldDate: string;
  totalPoints: number;
}
export class RecalculatePointsRequestModel {
  employeeOId: number;
  recalPntsComments: RecalculatePointsCommentModel[];
  newEvents: NewEvent[] = [] ;
}
export class RecalculatePointsCommentModel {
  eventOId: number;
  excludeFlag: boolean;
  reason: String;
  comments: string;
  previousPoints : number;
  pointAdjustment :any;
  breakCredit :string = 'Y';
  vettingResult : string;
  vettingAdjustmentType : string;
  layHistOid : number;
  systemGenerated : String;
  startDate : string;
  statusCode : string;
  expiryDateEdited: string;
  priorLCCEvent: boolean;
}

export class NewEvent{
  statusCode : string;
  reason : string;
  points : number;
  eventDate :string;
  eventTime :string;
  comments : string;
  breakCredit : string;
}

export class IndividualSnapshotSearchModel {
  result: string;
  data: IndividualSnapshotModel;
  messages: MessageModel
}
export class CalendarViewResponseModel {
  result: string;
  data: CalendarEventVO;
  messages: MessageModel
}
export class CalendarEventVO{
  employeeName : string;
  employeeId : string;
  goodLayoffDates : String[];
  badLayoffDates : String[];
  events : CalendarEvent[];
}

export class CalendarEvent {
  start: string;
  title : string;
  layoffType : string;
}
export class IndividualSnapshotModel {
  employeeId: string;
  employeeOid : number;
  employeeName: string;
  starts: number;
  missedCalls: number;
  weekendLayoffs: number;
  nonCompLayoffs: number;
  totalPoints: number;
  holidayLayoffHistoryVOs = [];
  missedCallHistoryVOs = [];
  disciplineHistoryVO = [];
  disciplineHistoryVOs = [];
  startdate: string;
  enddate: string;
  credits: number;
}
export class holidayLayoffHistoryVOs {
  date: string;
  name: string;
  statusCode: string;
  time: string;
}
export class missedCallHistoryVOs {
  statusCode: string;
  locationCode: string;
  locationId: string;
  missedCallDate: string;
  jobId: string;
}
export class disciplineHistoryVO {
  statusCode: string;
  description: string;
  date: string;
}
export class disciplineHistoryVOs {
  statusCode: string;
  description: string;
  date: string;
}
export class RegionOrServiceUnitDetails {
  result: string;
  messages: MessageModel;
  data: RegionOrServiceUnitDetailsVO[];
}
export class RegionOrServiceUnitDetailsVO {
  employeeId: string;
  employeeFirstName: string;
  employeeLastName: string;
  region: string;
  serviceUnit: string;
  locationCode: string;
  locationId: string;
  stationNumber: string;
  trainMasterTerritoryDesc: string;
  pointsAssesed: number;
  totalPoints: number;
  employeeOid: String;
  initials: String;
  starts: number;
  DD_SD: any;
  eventCout: number;
  statusCode : String;
  holidayLayoffs: number;
  weekendLayoffs: number;
  nonCPSDLayoffs: number;
  portalMessageDate: String;
  noisent: String;
  hearingDate: String;
  supervisorName: String;
  layOffDate: String;
  craftCode: String;
  hireDate: String;
}
export class SearchChangeHistoryMasterModel {
  result: string;
  messages: MessageModel;
  data: ChangeHistorySearchResultVO[];
}
export class ChangeHistorySearchResultVO {
  screenName: string;
  action: string;
  actionDate: string;
  updatedBy: string;
  changeHistoryOId: number;
}
export class SearchchangeHistoryModel {
  screenName: string = '';
  fromDate: string = '';
  toDate: string = '';
  updatedBy: string = '';
  bussinessAction: string = '';
  action: string = '';
}
export class changeHistoryDetailsMasterModel {
  result: string;
  messages: MessageModel;
  data: ChangeHistorySearchDetailVO;
}
export class ChangeHistorySearchDetailVO {
  action: string;
  actionDate: string;
  businessKeyOId: number;
  changeHistoryOId: number;
  createdBy: string;
  createdTime: string;
  screenName: string
  updatedBy: string;
}
export class ChangeHistoryDifferenceVO {
  field: string;
  oldValue: any;
  newValue: any;
}
export class AttendanceMonitorRequestVO {
  pointRangeVo: string[];
  regionsList: string[];
}
export class pointRangeVo {
  startRange: number
  endRange: number
}
export class SearchUsersSecurity {
  employeeOid: number;
  roleOid: number;
  regionOid: number;
  serviceUnitOid: number;
  craftCodes : any[];
  type: string;
}
export class SearchUsersSecurityResponse {
  name: string = '';
  employeeId: string = '';
  employeeOid: number = null;
  craftCode = '';
  defaultSecurityRole: SecurityRole;
  serviceUnits: ServiceUnit[];
  securityRoles: SecurityRole[];
  regions: SecurityRole[];
  defaultSecurityRoleName:string;
}
export class SecurityRole {
  roleOid: number;
  roleName: string = '';
  description: string = '';
}
export class SecurityScreenCapability {
  securityScreenCapabilityOid: number;
  screenCapabilityName: string = '';
  screenCapabilityDescprition: string = '';
  read: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
}
export class ServiceUnit {
  serviceUnitOid: number;
  serviceUnitName: string = '';
  regionOid: number;
  region: Region;
}
export class Region {
  regionOid: number;
  regionName: string = '';
}
export class EmployeeSecurity {
  employeeOid: number;
  employeeId: string = '';
  firstName: string = '';
  middleName: string = '';
  lastName: string = '';
  craftCode :string ='';
  defaultSecurityRoleOid: number;
  defaultSecurityRoleName:string;
  securityRoles: SecurityRole[];
  serviceUnits: ServiceUnit[];
  regions:Region[];

}
export class RaiseDispute {
  employeeScoreOid: number;
  reason: string;
  disputeCmnt: string;
  previousPoints: number;
  employeeOid: number;
  files: File[];
}

export class SearchEmployeeSecurityModel {
  result: string;
  messages: MessageModel;
  data: SearchUsersSecurityResponse[];
}
export class EditEmployeeSecurityModel {
  result: string;
  messages: MessageModel;
  data: EmployeeSecurity;
}
export class EmployeePointRangeMasterModel {
  result: string;
  messages: MessageModel;
  data: EmployeeOnPointRangeVO;
}
export class EmployeeOnPointRangeVO {
  "0-10": string;
  "11-20": string;
  "21-30": string;
  "31-40+": string;
}
export class EmployeeServiceUnitMasterModel {
  result: string;
  messages: MessageModel;
  data: EmployeeServiceUnitDetailsVO;
}
export class EmployeeServiceUnitDetailsVO {
  employeesCountMap: EmployeeOnPointRangeVO;
  regionsList: string[];
}
export class SecurityRoleSearchMasterModel {
  result: string;
  messgaes: MessageModel;
  data: any;
}
export class SecurityRoleLiteVO {
  roleOid: number;
  roleName: String;
}
export class SecurityEditRoleMasterModel {
  result: string;
  messages: MessageModel;
  data: SecurityRoleVO;
}
export class SecurityRoleVO {
  roleOid: number;
  roleName: string;
  description: String
  screenCapabilities: SecurityScreenCapabilityVO[];
  featureCapabilities: FeatureRoleVO[];
  createdBy = 'cams';
}
export class SecurityScreenCapabilityVO {
  securityScreenCapabilityOid: number;
  screenName: String
  screenCapabilityDescprition: String
  create = false;
  read = false;
  update = false;
  delete = false;
  applicationAttributeOid :number;
}
export class PickListRegionsModel {
  pickListConfig: any;
  availableList: Region[] = [];
  selectedList: Region[] = [];
}
export class PickListRolesModel {
  pickListConfig: any;
  availableList: SecurityRole[] = [];
  selectedList: SecurityRole[] = [];
}
export class PickListServiceUnitsModel {
  pickListConfig: any;
  availableList: ServiceUnit[] = [];
  selectedList: ServiceUnit[] = [];
}
export class saveSecurityRolesMasterModel{
  result: string;
  messages: MessageModel;
  data:any;
}
export class saveSecurityUserMasterModel {
  result: string;
  messages: MessageModel;
  data:any;
}
export class updateSecurityRoleMasterModel {
  result: string;
  messages: MessageModel;
  data:any;
}
export class globalPropertiesNameMasterMOdel {
  result: string;
  messages: MessageModel;
  data:[];
}
export class GlobalPropertiesSearchMasterModel {
  result: string;
  messages: MessageModel;
  data:ApplicationAttributeVO[];
}
export class ApplicationAttributeVO {
  name: String
  description: String
  value: string
  activeFlag: boolean
  type: String
  oid: number;
  edit: boolean;
}
export class activeProfileMasterModel {
  result: string;
  messages: MessageModel;
  data:boolean;
}
export class saveGlobalPropertiesMasterModel{
  result: string;
  messages: MessageModel[];
  data:ApplicationAttributeVO;
}
export class updateGlobalPropertiesMasterModel{
  result: string;
  messages: MessageModel[];
  data:ApplicationAttributeVO;
}
export class UserCapabilitiesMasterModel{
  result: string;
  messages: MessageModel[];
  data:UserCapabilitiesModel;
}
export class UserCapabilitiesModel {
  employeeOid : number;
  employeeId : number;
  firstName : string;
  middleName : string;
  lastName : string;
  defaultSecurityRoleOid : number;
  defaultRole:DefaultRoleVO;
  securityRoles:SecurityRolesVO[];
  serviceUnits : null
  regions : null
}
export class DefaultRoleVO {
  roleOid:number;
  roleName:string;
  description:string;
  menuList: string[];
  screenCapabilities:screenCapabilitiesVO[];
  featureCapabilities: FeatureRoleVO[];
}
export class screenCapabilitiesVO {
  securityScreenCapabilityOid:number;
  screenName:string;
  screenCapabilityDescprition:string;
  create:boolean;
  read:boolean;
  update:boolean;
  delete:boolean;
  applicationAttributeOid:number
}
export class SecurityRolesVO {
  roleOid : number;
  roleName :string;
  description : string;
}
export class changeHistoryMasterModel {
  result: string;
  messages: MessageModel;
  data:LayoffEventVO[];

}
export class LayoffEventVO {
createdUser:string;
comments:string;
date:string;
dayOfWeek:string;
eventOid: number;
excludeEvent: boolean
jobId:number
locationCode:string;
locationId:string;
points:number;
previousPoints : number;
startTime:string;
statusCode:string;
reason:string;
createdTimeStamp:string
adjustment :string;
vettingResult : string;
actualPoints : string;
}
export class roleDeleteMasterModel {
  result: string;
  messages: MessageModel;
  data:any;
}
export class globalPropertiesDeleteMasterModel{
  result: string;
  messages: MessageModel;
  data:any;
}
export class userDeleteMasterModel {
  result: string;
  messages: MessageModel;
  data:any;
}
export class DisputereviewqueueModel {
  result: string;
  data: DisputereviewqueueModel[];
  messages: MessageModel
}
export class DisputequeueModel {
  result: string;
  data: DisputeQueueSearchModel[];
  messages: MessageModel
}

export class MedicalQueueModel {
  result: string;
  data: MedicalQueueVO[];
  messages: MessageModel
}

export class ServiceUnitRangeMasterModel {
  result: string;
  messages: MessageModel;
  data:serviceUnitVO[];
}
export class serviceUnitVO {
  regionName:string;
  seviceUnitDetailsList:serviceUnitDetailsList[];
}
export class serviceUnitDetailsList {
  serviceUnitName : string;
  serviceUnitId : string;
  employeeCount : number;
}
export class DismissalReviewQueueMasterModel {
  result: string;
  messages: MessageModel;
  data:dismissalReviewModel;
}
export class dismissalReviewModel{
    employeeName:String;
    employeeId:number;
    jobsAndDHs:number;
    missedCallLayOffs:number;
    sickLayOffs:number;
    totalNonCompLayOffs:number;
    holidayLayOffs:number;
    weekendLayOffs:number;
    totalPoints:number;
    daysForGoodAttendanceCredit:number;
    creditEligibiltyDate :string;
    startDate:string;
    endDate:string;
    layoffEvents:LayoffEventVO[];
    employeeStatus: string;
    attendanceStatus: string;
    retentionDate: string;
}
export class RegionVO {
  regionCode: String;
  regionName: String;
  regionOid: number;
}
export class ServiceUnitDropDownVO {
  serviceUnitName : String;
  serviceUnitNumber : String;
  serviceUnitOid : number;
}
export class DisputeReviewQueueSearchModel {
  checked = false;
  employeeOid: number;
  regionId: number;
  serviceId: number;
  employeeId: number;
  emplfirName: string;
  emplLastName: string;
  emplMidName: string;
  trainSymbol: string;
  depDate: string;
  loco: string;
  tripMiles: string;
  intvName: string;
  tieUpCmnt: string;
  fuelLoss: number;
  disputeCmnt: string;
  disputeFlag: string;
  route: string;
  suprId: number;
  suprLastName: string;
  suprFirstName: string;
}
export class DisputeQueueSearchModel {
  employeeOid: number;
  employeeId: string;
  emplfirName: string;
  emplLastName: string;
  date: string;
  name: string;
  regionId: number;
  jobId: string;
  serviceId: number;
  statusCodeDescription: string;
  district: string;
  subDistrict: string;
  emplMidName: string;
  policySec: string;
  statusCode: string;
  eventDate: string;
  disputeDate: string;
  previousPoints: string;
  disputeCmnt: string;
  suprLastName: string;
  suprFirstName: string;
  supName: string;
  employeeScoreOid :number;
}
export class DistrictVO{
   districtName :string;
  districtCode:string;
  regOid :number;
  regionName:string;
}


export class SubDistrictVO{
  timeZoneCode :string;
  districtCode:string;
  subDistrictName :string;
  subDistrictCode:string;
}
export class MedicalQueueSearchRequestVO{
  employeeOid : number;
  districtCode : string;
  subDistrictCode : string;
  craftCode : string;
}
export class MedicalQueueVO{
  checked = false;
  medicalQueueOid :number;
  employeeId :string;
  employeeOid :number;
  firstName :string;
  lastName :string;
  name :string;
  scoreEventOid : number;
  layHistOid : number;
  craftCode :string;
  district : DistrictVO;
  subDistrict : SubDistrictVO;
  eventDate : string;
  factsDate :string;
  driverDate:string;
  notes :string;
  lastUpdatedUser :string;
  updatedAt :string;
}
export class DisputeModal {
  date: string;
  startTime: string;
  statusCode: string;
  jobId: string;
  previousPoints: number;
  employeeOid : number;
  employeeScoreOid : number;
}
export class DisputeMonitor {
  date: string;
  startTime: string;
  reason: string;
  reasonCode: string;
  eventOid: number;
  jobId: string;
  points: number;
  pointAccumulated: number;
  previousPoints: number;
  mgr: string;
  mgrToCC: string[];
  mangComments: string;
  creditEvent: boolean;
}

export class SearchBoardType {
  
  boardType:String;
  boardCode:String;
 
}
export class SearchBoardTypeResponse {
  boardTypeOid:number;
  value: string = '';
  Oid:number;
  boardType: string='';
}

export class SearchBoardTypeModel {
  result: string;
  messages: MessageModel;
  data: SearchBoardTypeResponse[];
}


export class BoardType {
  boardTypeOid: number;
  boardType:String;
  boardName:String;
  appAttrOid:number;
  availableBoardCodes:BoardTypeCodeVO[];
  selectedBoardCodes:BoardTypeCodeVO[];
}

export class BoardTypeCodeVO {
    boardTypeOid:number;
    Oid:number;
	  value:String;
}
export class EditBoardTypeCodesModel {
  result: string;
  messages: MessageModel;
  data: BoardType;
}
export class saveBoardTypeCodesModel {
  result: string;
  messages: MessageModel;
  data:any;
}
export class updateBoardTypeCodesModel {
  result: string;
  messages: MessageModel;
  data:any;
}
export class SendToAPDSResponseModel {
  result: string;
  data: SendToAPDSResponse;
  messages: MessageModel
}
export class SendToAPDSResponse{
  successfulEmployees : EmployeeAPDSResponse[];
  failedEmployees : EmployeeAPDSResponse[];
}
export class EmployeeAPDSResponse{
 employeeId :string;
 employeeOId : number;
 errorCode : string;
 referenceNumber :string;
 description :string;
}
export class SendTOFACTSVO {
  employeeOId : number;
  endDate : string;
  startDate : string;
  knowledgeDate : string;
  caseType : string;
}
export class JobsStatusResponseModel {
  result: string;
  data: JobControlVO[];
  messages: MessageModel
}
export class JobControlVO{
  currentJobStatusVO : JobsStatus = new JobsStatus();
  previousJobStatusVO : JobsStatus = new JobsStatus();
  nextQuartzFireTime : string;
}
export class JobsStatus{
  name : string;
  status: string;
  startTime: string;
  endTime :string;
  isJobSuccessFlag : String;
  }
export class MissedcallReviewSearchResponseModel {
  result: string;
  data: MissedcallReview[];
  messages: MessageModel
}
export class MissedcallReview{
  employeeOId : number;
  name : string;
  employeeId : string;
  region : string;
  serviceUnit : string;
  statusCode : string;
  currentBoard : string;
  assocaitedBoard : string;
  date :string;
  time :string;
  dateTime : string;
  eventOId : number;
  points :number;
  jobId :string;
  jobDate : string;
  layoffUserId :string;
  deskId :string;
  vaccancyProcedure :string; 
  phoneInfo :string;
  markupInfo :string;
  vettingAdjustment :string;
}
export class  EmployeeSearchResultsVO{
  employeeOid : number;
  employeeId: string;
  employeeName : string;
  serviceUnit : string;
  region : string;
  isActive : boolean;
  isOnHold : boolean;
  status :string;
}
export class EmployeeSearchResultsVOModel {
  result: string;
  data: EmployeeSearchResultsVO[];
  messages: MessageModel;
}
export class EmployeeDetailsVOModel {
  result: string;
  data: EmployeeDetailsVO;
  messages: MessageModel;
}

export class EmployeeDetailsVO{
  employeeOid : number;
  employeeId: string;
  employeeName : string;
  serviceUnit : string;
  region : string;
  isActive : boolean;
  isOnHold : boolean;
  isTEYEmployee :boolean;
  isSentToDismissalReview : boolean;
  isSentToDisciplinarySystem : boolean;
  currentAssociatedBoard : string;
  permanentBoard : string;
  inactiveDate : string;
  sentToDismissalReviewDate : string;
  sentToDisciplinaryDate : string;
  lastEventDate : string;

}
 export  class EmployeePermBoardHistoryVO{
  date :string;
  boardType : string;
  boardCode : string;
 }

 export class EmployeePermBoardHistoryVOModel {
  result: string;
  data: EmployeePermBoardHistoryVO[];
  messages: MessageModel;
}
export  class EmployeeHistoryVO{
  createdDate :string;
  employeeStatus : string;
  }
  
export class EmployeeHistoryVOModel {
  result: string;
  data: EmployeeHistoryVO[];
  messages: MessageModel;
}
export class LateToReportModel {
  employeeOId: number;
  eventDate: string;
  employeeId: number;
  eventTime: string;
  jobId: string;
  comments: string;
  statusCode: string;
}
